import './App.css';
import Pricing from './AppBar'

function App() {
  return (
    <div className="App">
      <Pricing />
    </div>
  );
}

export default App;
